.c-divider {
  height: 150px;
  background: {
    position: 50% 50%;
    size: cover;
    repeat: no-repeat;
  }

  @include bp-medium() {
    height: 400px;
  }
}