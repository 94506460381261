/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

 /* titillium-web-regular - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/titillium-web-v6-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/titillium-web-v6-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/titillium-web-v6-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/titillium-web-v6-latin_latin-ext-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
