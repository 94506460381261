.c-news {
  padding: 60px 20px;
  background: $color-light-grey;
}

.c-news-title {
  text-align: center;
  font-weight: 700;
  color: $color-red;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.c-news-grid {
  margin-top: 40px;
  @include clearfix();
}

.c-news-calendar {
  width: 120px;
  float: left;
  position: relative;
  font-weight: 700;
}

.c-news-icon-calendar {
  width: 90px;
  padding-left: 10px;
}

.icon-calendar {
  fill: $color-red;
}

.c-news-grid-item {
  @include clearfix();
  border-bottom: 1px solid $color-red;
  padding-bottom: 30px;
  margin-top: 30px;
}

.c-news-content {
  @include clearfix();
  width: calc(100% - 120px);
  float: left;

  .c-btn {
      margin-top: 20px;
  }
}

.c-news-calendar-date {
  position: absolute;
  top: 33px;
  font-size: 1.3em;
  left: 22px;
  color: $color-red;
}

.c-news-content-title {
  display: block;
  font-size: 1.3em;
  font-weight: 700;
  color: $color-red;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.c-news-content-excerpt {
  color: $color-dark-grey!important;
  p {
    color: $color-dark-grey;
  }
}