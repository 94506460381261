.alm-load-more-btn {
  display: inline-block;
  margin-top: 50px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  .u-show-more--plus {
      font-size: 40px;
      font-weight: 900;
      color: $color-red;
      line-height: 1.2;
  }
  .u-show-more--text {
      color: $color-black;
      font-weight: 100;
  }
}

.alm-btn-wrap {
  text-align: center;

  .done {
      display: none;
  }
}
