/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  font-size: 0.875rem;
  background: $color-red;
  padding: 20px;
  @include clearfix();

  p {
    margin: 0;
    color: $color-white;
  }

  @media (max-width: 450px) {
    > p {
      float: none;
      text-align: center;
    }
  }
}

.c-footer-link {
  color: #fff;
  font-weight: 700;
}
