.mb-20 {
  margin-bottom: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.grey {
  color: $color-medium-grey;
}

.--mobile {
  @media (min-width: 767px) {
    display: none;
  }
}
.--desktop {
  @media(max-width: 768px) {
    display: none;
  }
}
