@charset "UTF-8";
/*
  Project: Domseniora
  Author: Daniel
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* titillium-web-regular - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/titillium-web-v6-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-regular.woff2") format("woff2"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-regular.woff") format("woff"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-regular.ttf") format("truetype"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-regular.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */
}

/* titillium-web-600 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/titillium-web-v6-latin_latin-ext-600.eot");
  /* IE9 Compat Modes */
  src: local("Titillium Web SemiBold"), local("TitilliumWeb-SemiBold"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-600.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-600.woff2") format("woff2"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-600.woff") format("woff"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-600.ttf") format("truetype"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-600.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */
}

/* titillium-web-700 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/titillium-web-v6-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-700.woff2") format("woff2"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-700.woff") format("woff"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-700.ttf") format("truetype"), url("../assets/fonts/titillium-web-v6-latin_latin-ext-700.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2.1rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
  margin: 20px 0;
  height: 1px;
  box-sizing: border-box;
  width: 200px;
  border: 0.5px solid #a71d33;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: "Titillium Web", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #67686a;
  text-decoration: none;
}

a:hover {
  color: #fafafa;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

p {
  color: #67686a;
}

.o-hamburger {
  display: block;
  cursor: pointer;
  height: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 40px;
  z-index: 999999999999;
  position: relative;
}

.o-hamburger span {
  display: block;
  width: 40px;
  height: 3px;
  background: #000;
  margin-top: 5px;
  transform-origin: 4px 0;
  position: relative;
}

.o-hamburger span:nth-child(1) {
  margin-top: 0;
  transform-origin: 0% 0%;
}

.o-hamburger span:nth-child(3) {
  transform-origin: 0% 100%;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--wrap {
  flex-wrap: wrap;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-about {
  background: #fafafa;
  padding: 30px 15px;
}

.c-about .alm-btn-wrap {
  display: block;
}

.c-about-title {
  text-align: center;
  font-weight: 700;
  color: #a71d33;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.c-about-content--hidden {
  display: none;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border: 1px solid;
  font-weight: 700;
  letter-spacing: 1.5px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #67686a;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #fafafa;
}

.c-btn--gallery {
  font-size: 1rem;
  border-radius: 12px;
  padding: 6px 18px;
  text-transform: uppercase;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.15rem 1.3rem;
}

.c-btn--large {
  padding: 0.5rem 1.3rem;
}

/* Border color variants
   ========================================================================== */
.c-btn--white {
  border-color: #fff;
  color: #fff;
  transition: all 0.3s ease-in;
}

.c-btn--white:hover {
  background: #fff;
  color: #000;
}

.c-btn--red {
  background: #a71d33;
  color: #fff;
  text-transform: uppercase;
}

.c-btn--red a {
  color: #fff;
  text-transform: uppercase;
}

.c-btn--red:hover {
  background-color: #fff;
  border-color: #a71d33;
  color: #a71d33;
}

.c-btn--red:hover a {
  color: #a71d33;
}

.c-btn-go-back-wrap {
  width: 100%;
  text-align: center;
}

.c-btn-go-to-website {
  text-align: center;
  display: inline-block;
}

.big-red-arrow {
  font-size: 24px;
  font-weight: 700;
  color: #a71d33;
  display: inline-block;
}

.c-btn-wrapper {
  padding: 30px 10px;
  text-align: center;
}

.alm-load-more-btn.loading {
  display: none;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-contact {
  background: #fafafa;
  padding: 70px 15px 50px;
}

.c-contact-title {
  text-align: center;
  font-weight: 700;
  color: #a71d33;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.cookies {
  position: fixed;
  background: #eee;
  max-width: 100%;
  bottom: 0;
  display: none;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: 400;
  z-index: 10000;
  padding: 10px 20px;
  text-align: center;
}

.cookies a {
  color: #67686a;
  text-decoration: underline;
}

.accept-cookie {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: inline;
}

.cookie-toaccept {
  display: block;
}

.c-divider {
  height: 150px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .c-divider {
    height: 400px;
  }
}

.c-download {
  background: #fafafa;
  padding: 70px 15px 50px;
}

.c-download-list {
  padding: 20px 0;
}

.c-download-list__item {
  display: block;
  margin: 50px 0;
}

.c-download-list__item--icon {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.c-download-list__item--title {
  font-size: 1.2em;
  color: #a71d33;
  text-transform: uppercase;
  margin-left: 1.2em;
  font-weight: bold;
  display: inline-block;
}

.c-footer-before {
  background: #fafafa;
  padding: 20px;
}

@media (min-width: 650px) {
  .c-footer-before {
    display: flex;
    padding: 30px;
  }
}

.c-footer-before__item {
  margin: 30px 0;
  flex: 1;
}

@media (min-width: 650px) {
  .c-footer-before__item {
    margin: 0;
    display: flex;
    padding: 30px;
    flex-direction: column;
  }
  .c-footer-before__item .c-topbar__icon {
    display: flex;
    flex-direction: column;
  }
  .c-footer-before__item .c-topbar__icon span {
    margin: 0;
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .c-footer-before__item {
    margin: 0;
    display: flex;
    padding: 30px;
  }
  .c-footer-before__item .c-topbar__icon {
    display: block;
  }
  .c-footer-before__item .c-topbar__icon span {
    margin: 0;
    margin-left: 15px;
    width: calc(100% - 55px);
  }
}

.c-footer-before__item .c-footer-before__desc {
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.2;
  color: #67686a;
}

.c-footer-before__logo {
  width: 120px;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 0.875rem;
  background: #a71d33;
  padding: 20px;
}

.c-footer:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-footer p {
  margin: 0;
  color: #fff;
}

@media (max-width: 450px) {
  .c-footer > p {
    float: none;
    text-align: center;
  }
}

.c-footer-link {
  color: #fff;
  font-weight: 700;
}

form ul:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

form ul li input, form ul li textarea {
  background: #fafafa;
  border: 1px solid #67686a;
}

form ul li input {
  padding: 20px !important;
}

form ul li label {
  display: none !important;
}

form ul li input[type="checkbox"] ~ label {
  display: inline !important;
  font-weight: 700;
  position: relative;
  top: 2px;
  left: 5px;
  font-size: 14px !important;
}

form ul li textarea {
  padding: 12px !important;
  margin-top: 20px;
}

form .gfield_description {
  font-size: 13px !important;
}

form .gfield_description a {
  color: #a71d33;
  text-decoration: underline;
}

form .gform_footer {
  text-align: center;
}

form input[type="submit"] {
  display: inline-block;
  color: #fff;
  background: #a71d33;
  padding: 20px 30px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 2px;
  border: 1px solid #a71d33;
  transition: all 0.3s ease-in-out;
}

form input[type="submit"]:hover {
  color: #a71d33;
  background: transparent;
}

.c-form-col2 {
  clear: none !important;
}

.c-form-col2 input {
  width: 100%;
}

@media (min-width: 760px) {
  .c-form-col2 {
    width: 50%;
    float: left;
  }
}

.c-form-req {
  color: #a71d33;
}

.c-gallery {
  padding: 100px 0;
  padding: 30px 15px;
}

.c-gallery__loop {
  margin-top: 50px;
}

.c-gallery-title {
  text-align: center;
  font-weight: 700;
  color: #a71d33;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.c-gallery__item {
  display: inline-block;
  position: relative;
  margin: 0 auto;
}

.c-gallery-item-image {
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.c-gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

.c-gallery-item-title {
  font-weight: 900;
}

.c-gallery-item-title, .c-gallery-item-info {
  color: #a71d33;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .c-gallery-item-title, .c-gallery-item-info {
    font-size: 1rem;
  }
}

.c-gallery__item {
  display: block;
  margin: 0 0 2.5em 0;
  transition: opacity 200ms ease-in-out;
  flex: 0 0 calc(33.33% - 2.5em);
  overflow: hidden;
}

@media (min-width: 768px) {
  .c-gallery__item {
    margin: 0 2.5em 2.5em 0;
  }
}

.c-gallery__item img {
  height: 100%;
}

.c-gallery__item:hover {
  opacity: 0.7;
}

.c-gallery__thumbnail {
  margin-right: 10px;
  height: 150px;
  /* set min-width,
     allow images to determine cell width */
  min-width: 150px;
  max-width: 100%;
}

.c-gallery__thumbnail img {
  height: 150px;
  /* set min-width,
       allow images to determine cell width */
  min-width: 150px;
  max-width: 100%;
}

.c-gallery .flickity-viewport {
  height: 150px !important;
}

.c-gallery__image {
  display: block;
}

.c-gallery-navigation-prev {
  float: left;
}

.c-gallery-navigation-next {
  float: right;
}

.c-gallery-navigation-text {
  display: none;
}

@media (min-width: 768px) {
  .c-gallery-navigation-text {
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 16px;
    color: #67686a;
  }
}

.c-gallery-navigation {
  padding: 0 15px;
}

@media (min-width: 1024px) {
  .c-gallery-navigation {
    padding: 0;
  }
}

.c-gallery-navigation-next__icon {
  position: relative;
  top: 5px;
  transform: rotate(180deg);
}

.c-gallery-navigation-prev__icon {
  position: relative;
  top: 35px;
}

.c-gallery-navigation-prev__icon, .c-gallery-navigation-next__icon {
  width: 60px;
  display: inline-block;
}

.c-gallery-navigation-prev__icon svg g, .c-gallery-navigation-next__icon svg g {
  fill: #67686a;
}

.single-galeria .c-text-title {
  color: #a71d33;
}

.c-galeria-single-hero {
  width: 100%;
}

@media (max-width: 500px) {
  .flickity-button {
    display: none !important;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: relative;
}

.c-header .c-image img {
  width: 100% !important;
}

.c-header-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.c-header-bottom .c-header-scroll {
  display: inline-block;
  font-size: 1.2em;
  text-transform: uppercase;
  color: #a71d33;
  padding: 20px;
}

.c-header-bottom .c-header-scroll span {
  position: relative;
  margin-right: 10px;
  top: 2px;
  letter-spacing: 1.5px;
}

.c-header-bottom .c-header-scroll img {
  width: 35px;
  height: 35px;
}

.c-headroom {
  padding: 10px 15px;
  display: flex;
  position: absolute;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  background: #fff;
  left: 0;
  right: 0;
  width: auto !important;
  transition: all 0.3s cubic-bezier(0, 0.82, 0.011, 1);
  z-index: -1;
}

.c-headroom .c-topbar__hamburger {
  margin-top: 15px;
}

.c-headroom .c-topbar__hamburger .c-topbar__sm {
  position: absolute;
  top: 1.9em;
  right: 5.2em;
}

.c-headroom .c-topbar__hamburger .c-topbar__sm img {
  vertical-align: baseline;
}

.c-headroom.stucked {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  z-index: 900000;
}

.c-headroom__logo {
  width: 100px;
  margin-left: 20px;
}

@media (min-width: 1180px) {
  .c-headroom__logo {
    margin-left: 80px;
  }
}

.c-headroom__menu {
  display: none;
}

@media (min-width: 1180px) {
  .c-headroom__menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
  }
}

.c-headroom__menu .c-topbar_lang {
  margin-right: auto;
  margin-left: 30px;
}

.c-headroom__menu .c-topbar_lang a {
  color: #a71d33;
}

.c-headroom__menu .c-topbar_lang .inactive {
  color: #a8a8a9 !important;
}

.c-headroom__menu .c-main-nav {
  margin-right: 20px;
}

.c-hero {
  height: calc(100vh - 60px);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.job-listing {
  margin: 50px 0;
}

.job-listing__item {
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-bottom: #a71d33 solid 1px;
}

@media (min-width: 600px) {
  .job-listing__item {
    display: flex;
  }
}

.job-listing__img-wrap {
  display: inline-block;
  position: relative;
}

@media (min-width: 600px) {
  .job-listing__helper {
    width: 40%;
  }
}

.c-job-listing__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

.job-listing__content {
  margin-top: 20px;
}

.job-listing__content p {
  margin-top: 15px;
  margin-bottom: 0;
}

.job-listing__content ul {
  color: #67686a;
}

.job-listing__content ul li {
  margin: 0;
}

@media (min-width: 600px) {
  .job-listing__content {
    width: 60%;
    padding-left: 25px;
  }
}

.job-listing__title {
  color: #a71d33;
  font-size: 1.4em;
  margin-bottom: 20px;
}

.job-listing__title span {
  text-transform: uppercase;
  font-weight: bold;
}

.job-listing__description {
  font-size: 1.1em;
}

.apply-button {
  margin-top: 20px;
}

.c-jobs .c-gallery-navigation-text, .c-download .c-gallery-navigation-text {
  color: #a71d33 !important;
  display: inline-block !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

.c-jobs .c-gallery-navigation-prev__icon svg g, .c-download .c-gallery-navigation-prev__icon svg g {
  fill: #a71d33 !important;
}

.c-uslugi-listing {
  margin: 50px 0;
}

.c-uslugi-listing__item {
  padding: 40px 0;
}

@media (min-width: 640px) {
  .c-uslugi-listing__item {
    display: flex;
  }
}

.c-uslugi-listing__content {
  padding-top: 30px;
}

@media (min-width: 640px) {
  .c-uslugi-listing__content {
    padding-left: 40px;
  }
}

.c-uslugi-listing__img-wrap {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-uslugi-listing__img-wrap img {
  width: auto;
  height: 80px;
}

.c-uslugi-listing__img-wrap h3 {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: inline-block;
  margin: 0;
}

.c-main-nav__item {
  display: inline-block;
  font-weight: 400;
  list-style: none;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-left: 2.5em;
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  display: inline-block;
  text-decoration: none;
  color: #4d4d4f;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 600;
}

.c-main-nav__link:hover {
  color: #a71d33;
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: #000;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid black;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

/* ==========================================================================
   #MAIN NAV MOBILE
   ========================================================================== */
.c-main-nav--mob {
  display: block;
  z-index: 9999999999999999999999999;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  margin: 0;
  background: #fafafa;
  width: 100%;
  max-width: 400px;
  transform: translate3d(400px, 0, 0);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
}

.c-main-nav--mob .c-topbar_lang {
  margin-top: 20px;
}

.c-main-nav--mob .c-topbar_lang a {
  color: #a71d33;
}

.c-main-nav--mob .c-topbar_lang .inactive {
  color: #a8a8a9 !important;
}

.menu-active {
  transform: translate3d(0, 0, 0);
}

.c-main-nav__close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #67686a;
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
}

.c-main-nav__close:hover {
  color: #000;
}

.c-main-nav-wrap--mob {
  text-align: center;
  position: relative;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c-main-nav__item--mob {
  font-weight: bold;
  list-style: none;
  padding: 0;
}

.c-main-nav__link--mob {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  font-size: 16px;
  color: #67686a;
  text-transform: uppercase;
}

.c-main-nav__link--mob:hover {
  color: #000;
}

.c-news {
  padding: 60px 20px;
  background: #fafafa;
}

.c-news-title {
  text-align: center;
  font-weight: 700;
  color: #a71d33;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.c-news-grid {
  margin-top: 40px;
}

.c-news-grid:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-news-calendar {
  width: 120px;
  float: left;
  position: relative;
  font-weight: 700;
}

.c-news-icon-calendar {
  width: 90px;
  padding-left: 10px;
}

.icon-calendar {
  fill: #a71d33;
}

.c-news-grid-item {
  border-bottom: 1px solid #a71d33;
  padding-bottom: 30px;
  margin-top: 30px;
}

.c-news-grid-item:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-news-content {
  width: calc(100% - 120px);
  float: left;
}

.c-news-content:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-news-content .c-btn {
  margin-top: 20px;
}

.c-news-calendar-date {
  position: absolute;
  top: 33px;
  font-size: 1.3em;
  left: 22px;
  color: #a71d33;
}

.c-news-content-title {
  display: block;
  font-size: 1.3em;
  font-weight: 700;
  color: #a71d33;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.c-news-content-excerpt {
  color: #8c8d8e !important;
}

.c-news-content-excerpt p {
  color: #8c8d8e;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-pricing__item {
  border: 1px solid #a8a8a9;
  padding: 35px 25px;
  min-height: 350px;
}

.c-pricing__item--title {
  font-size: 1.6em;
  font-weight: bold;
  color: #a71d33;
  border-bottom: #a71d33 1px solid;
  padding-bottom: 15px;
}

.c-pricing__item--list {
  margin-top: 30px;
  color: #67686a;
  font-size: 1em;
  line-height: 1.1;
}

.c-services {
  padding: 50px 15px;
  background: #fafafa;
}

.c-services-title {
  text-align: center;
  font-weight: 700;
  color: #a71d33;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.c-service-item {
  margin: 10px 0;
  flex: 0 0 calc(100%);
}

@media (min-width: 565px) {
  .c-service-item {
    margin: 10px;
    flex: 0 0 calc(50% - 20px);
  }
}

@media (min-width: 670px) {
  .c-service-item {
    margin: 10px;
    flex: 0 0 calc(33% - 20px);
  }
}

@media (min-width: 980px) {
  .c-service-item {
    margin: 10px;
    flex: 0 0 calc(25% - 20px);
  }
}

.c-service-item img {
  height: 80px;
  display: block;
  margin: 50px auto 10px;
}

.c-service-item h3 {
  font-size: 14px;
  text-align: center;
  padding: 15px;
  color: #fff;
  margin-bottom: 40px;
}

.c-services-row {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bg-service-grey {
  background: #8c8d8e;
}

.bg-service-grey:hover {
  background: #a8a8a9;
}

.bg-service-red {
  background: #a71d33;
}

.bg-service-red:hover {
  background: #be5665;
}

.c-text {
  padding: 80px 20px;
}

.c-text-title {
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.c-text-title__desc {
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: -20px;
}

.c-text-content {
  margin-top: 40px;
}

.c-text-content h2 {
  font-size: 16px;
  text-transform: uppercase;
}

.c-text-btn-link {
  margin: 50px auto 0;
  display: block;
  text-align: center;
}

.c-text-btn-link a {
  color: #000;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  padding: 10px 15px;
  border: 1px solid #000;
  transition: all 0.5s ease-in-out;
}

.c-text-btn-link a:hover {
  color: #fff;
  background: #000;
}

.c-topbar {
  display: flex;
  padding: 20px;
}

@media (min-width: 1214px) {
  .c-topbar {
    margin-top: 40px;
    padding: 20px 20px 0 20px;
  }
}

.c-topbar__logo img {
  width: 120px;
}

@media (min-width: 1214px) {
  .c-topbar__logo {
    width: 280px;
    padding-right: 15px;
    position: relative;
  }
  .c-topbar__logo img {
    width: 150px;
    position: absolute;
    bottom: 20px;
    right: 60px;
  }
}

.c-topbar__nav {
  display: none;
}

@media (min-width: 1214px) {
  .c-topbar__nav {
    display: block;
    width: calc(100% - 300px);
  }
}

.c-topbar__icon:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-topbar__icon p {
  margin: 0 !important;
}

.c-topbar__icon a {
  color: #67686a;
}

.c-topbar__icon img, .c-topbar__icon span {
  float: left;
}

.c-topbar__icon img {
  width: 40px;
}

.c-topbar__icon span {
  margin-left: 20px;
  font-size: 14px;
  line-height: 1.2;
  color: #67686a;
}

.c-topbar__divider {
  display: none;
}

@media (min-width: 1275px) {
  .c-topbar__divider {
    display: block;
    border-bottom: 2px dotted #a71d33;
  }
}

.c-topbar__menu {
  display: none;
}

@media (min-width: 1214px) {
  .c-topbar__menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
  }
  .c-topbar__menu .c-topbar_lang {
    margin-right: auto;
  }
  .c-topbar__menu .c-topbar_lang a {
    color: #a71d33;
  }
  .c-topbar__menu .c-topbar_lang .inactive {
    color: #a8a8a9 !important;
  }
  .c-topbar__menu > .c-main-nav {
    margin-right: 30px;
  }
}

.c-topbar__sm {
  position: relative;
  top: 3px;
}

.c-topbar__sm img {
  vertical-align: baseline;
}

.c-topbar__sm a {
  display: inline-block;
  width: 18px;
  margin-left: 5px;
}

.c-topbar__icons__layout {
  display: none;
}

@media (min-width: 1275px) {
  .c-topbar__icons__layout {
    display: flex;
    margin-bottom: 20px;
  }
}

.c-topbar__icons-item {
  flex: 1;
}

.c-topbar__hamburger {
  margin-left: auto;
  margin-top: 20px;
}

@media (min-width: 1214px) {
  .c-topbar__hamburger {
    display: none;
  }
}

.c-topbar__hamburger .c-topbar__sm {
  position: absolute;
  top: 3.1em;
  right: 5.5em;
}

.c-topbar__hamburger .c-topbar__sm img {
  vertical-align: baseline;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.aspect-ratio--object {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.c-header-slider {
  padding-bottom: 42%;
}

.c-header-slider .flickity-viewport {
  position: absolute;
  width: 100%;
}

.c-header-slider .flickity-page-dots {
  z-index: 50;
  bottom: 25px;
}

.c-header-slider .flickity-page-dots .dot {
  background: #fff;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.grey {
  color: #67686a;
}

@media (min-width: 767px) {
  .--mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .--desktop {
    display: none;
  }
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.alm-load-more-btn {
  display: inline-block;
  margin-top: 50px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.alm-load-more-btn .u-show-more--plus {
  font-size: 40px;
  font-weight: 900;
  color: #a71d33;
  line-height: 1.2;
}

.alm-load-more-btn .u-show-more--text {
  color: #000;
  font-weight: 100;
}

.alm-btn-wrap {
  text-align: center;
}

.alm-btn-wrap .done {
  display: none;
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.flickity-prev-next-button {
  background: none;
}

.flickity-prev-next-button:hover {
  background: none;
}

.flickity-prev-next-button.next, .flickity-prev-next-button.previous {
  width: 30px;
  height: 30px;
}

.flickity-prev-next-button.next {
  right: -50px;
}

.flickity-prev-next-button.previous {
  left: -50px;
}

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}

#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease;
}

#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft .4s ease-out;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}

.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

#map {
  height: 60vh;
}

#map img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

#map .leaflet-marker-pane img {
  filter: none;
  -webkit-filter: none;
}
