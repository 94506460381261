form {
  ul {
    @include clearfix();

    li {
      input, textarea {
        background: $color-light-grey;
        border: 1px solid $color-medium-grey;
      }

      input {
        padding: 20px!important;
      }

      label {
        display: none!important;
      }

      input[type="checkbox"] ~ label {
        display: inline!important;
        font-weight: 700;
        position: relative;
        top: 2px;
        left: 5px;
        font-size: 14px !important;
      }

      textarea {
        padding: 12px!important;
        margin-top: 20px;
      }
    }
  }

  .gfield_description {
    font-size: 13px !important;

    a {
      color: $color-red;
      text-decoration: underline;
    }
  }

  .gform_footer {
    text-align: center;
  }
  input[type="submit"] {
    display: inline-block;
    color: #fff;
    background: $color-red;
    padding: 20px 30px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 2px;
    border: 1px solid $color-red;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-red;
      background: transparent;
    }
  }
}

.c-form-col2 {
  clear: none!important;

  input {
    width: 100%;
  }

  @media (min-width: 760px) {
    width: 50%;
    float: left;
  }


}

.c-form-req {
  color: $color-red;
}