.o-hamburger {
  display: block;
  cursor: pointer;
  height: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 40px;
  z-index: 999999999999;
  position: relative;

  span {
      display: block;
      width: 40px;
      height: 3px;
      background: $color-black;
      margin-top: 5px;
      transform-origin: 4px 0;
      position: relative;
      &:nth-child(1) {
          margin-top: 0;
          transform-origin: 0% 0%;
      }
      &:nth-child(3) {
          transform-origin: 0% 100%;
      }
  }
}
