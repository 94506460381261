/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: inline-block;
  margin: 0;
}

.c-main-nav__item {
  display: inline-block;
  font-weight: 400;
  list-style: none;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-left: 2.5em;  

  &:hover .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__link {
  display: inline-block;
  text-decoration: none;
  color: $color-very-dark-grey;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 600;

  &:hover {
    color: $color-red;
  }
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: $color-black;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}


/* ==========================================================================
   #MAIN NAV MOBILE
   ========================================================================== */

   .c-main-nav--mob {
    display: block;
    z-index: 9999999999999999999999999;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    margin: 0;
    background: $color-light-grey;
    width: 100%;
    max-width: 400px;
    transform: translate3d(400px, 0, 0);
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;

    .c-topbar_lang {
      margin-top: 20px;

      a {
        color: $color-red;
      }

      .inactive {
        color: $color-lessdark-grey!important;
      }
    }
  }
  
  .menu-active {
    transform: translate3d(0, 0, 0);
  }
  
  .c-main-nav__close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: $color-medium-grey;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      color: $color-black;
    }
  }
  
  .c-main-nav-wrap--mob {
    text-align: center;
    position: relative;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .c-main-nav__item--mob {
    font-weight: bold;
    list-style: none;
    padding: 0;
  }
  
  .c-main-nav__link--mob {
    display: block;
    padding: 0.5em;
    text-decoration: none;
    font-size: 16px;
    color: $color-medium-grey;
    text-transform: uppercase;
  
    &:hover {
      color: $color-black;
    }
  }
  