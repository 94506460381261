.cookies {
  position: fixed;
  background: #eee;
  max-width: 100%;
  bottom: 0;
  display: none;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: 400;
  z-index: 10000;
  padding: 10px 20px;
  text-align: center;

  a {
    color: $color-medium-grey;
    text-decoration: underline;
   }
}

.accept-cookie {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: inline;
}

.cookie-toaccept {
  display: block;
}
