.c-headroom {
  padding: 10px 15px;
  display: flex;
  position: absolute;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  background: #fff;
  left: 0;
  right: 0;
  width: auto!important;
  transition: all 0.3s cubic-bezier(0, 0.82, 0.011, 1);
  z-index: -1;

  .c-topbar__hamburger {
    margin-top: 15px;    

    .c-topbar__sm {
      position: absolute;
      top: 1.9em;
      right: 5.2em;

      img {
        vertical-align: baseline;
      }
    }
  }
}

.c-headroom.stucked {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  z-index: 900000;
}

.c-headroom__logo {
  width: 100px;
  margin-left: 20px;

  @media (min-width: 1180px) {
    margin-left: 80px;
  }
}

.c-headroom__menu {
  display: none;
  
  @media (min-width: 1180px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
  }

  .c-topbar_lang {
    margin-right: auto;
    margin-left: 30px;

    a {
      color: $color-red;
    }

    .inactive {
      color: $color-lessdark-grey!important;
    }
  }

  .c-main-nav {
    margin-right: 20px;
  }
}