.c-download {
  background: $color-light-grey;
  padding: 70px 15px 50px;
}

.c-download-list {
  padding: 20px 0;
}

.c-download-list__item {
  display: block;
  margin: 50px 0;
}

.c-download-list__item--icon {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.c-download-list__item--title {
  font-size: 1.2em;
  color: $color-red;
  text-transform: uppercase;
  margin-left: 1.2em;
  font-weight: bold;

  display: inline-block;
}
