.c-footer-before {
  background: #fafafa;
  padding: 20px;

  @media (min-width: 650px) {

    display: flex;
    padding: 30px;
  }
}

.c-footer-before__item {
  margin: 30px 0;
  flex: 1;

  @media (min-width: 650px) {
    margin: 0;
    display: flex;
    padding: 30px;
    flex-direction: column;

    .c-topbar__icon {
      display: flex;
      flex-direction: column;

      span {
        margin: 0;
        margin-top: 15px;
      }
    }
  }

  @media (min-width: 1200px) {
    margin: 0;
    display: flex;
    padding: 30px;

    .c-topbar__icon {
      display: block;
      
      span {
        margin: 0;
        margin-left: 15px;
        width: calc(100% - 55px);
      }
    }
  }

  .c-footer-before__desc {
    margin-top: 18px;
    font-size: 14px;
    line-height: 1.2;
    color: $color-medium-grey;
  }
}

.c-footer-before__logo {
  width: 120px;
}

