.c-topbar {
  display: flex;
  padding: 20px;

  @media(min-width: 1214px) {
    margin-top: 40px;
    padding: 20px 20px 0 20px;
  }
}

.c-topbar__logo {

  
  img {
    width: 120px;
  }

  @media (min-width: 1214px) {
    width: 280px;
    padding-right: 15px;
    position: relative;

    img {
      width: 150px;
      position: absolute;
      bottom: 20px;
      right: 60px;
    }
  }
}

.c-topbar__nav {
  display: none;

  @media (min-width: 1214px) {
    display: block;
    width: calc(100% - 300px);
  }
}

.c-topbar__icon {
  @include clearfix();

  p {
    margin: 0!important;
  }

  a {
    color: $color-medium-grey;
  }

  img, span {
    float: left;
  }

  img {
   width: 40px;
  }

  span {
    margin-left: 20px;
    font-size: 14px;
    line-height: 1.2;
    color: $color-medium-grey;
  }
}

.c-topbar__divider {
  display: none;

  @media (min-width: 1275px) {
    display: block;
    border-bottom: 2px dotted $color-red;
  }
}

.c-topbar__menu {
  display: none;

  @media (min-width: 1214px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;

    .c-topbar_lang {
      margin-right: auto;

      a {
        color: $color-red;
      }

      .inactive {
        color: $color-lessdark-grey!important;
      }
    }
  
    > .c-main-nav {
      margin-right: 30px;
    }
  }
}

.c-topbar__sm {
  position: relative;
  top: 3px;

  img {
    vertical-align: baseline;
  }

  a {
    display: inline-block;
    width: 18px;
    margin-left: 5px;
  }
}

.c-topbar__icons__layout {
  display: none;

  @media (min-width: 1275px) {
    display: flex;
    margin-bottom: 20px;
  }
}

.c-topbar__icons-item {
  flex: 1;
}

.c-topbar__hamburger {
  margin-left: auto;
  margin-top: 20px;

  @media (min-width: 1214px) {
    display: none;
  }

  .c-topbar__sm {
    position: absolute;
    top: 3.1em;
    right: 5.5em;

    img {
      vertical-align: baseline;
    }
  }
}