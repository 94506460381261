
.aspect-ratio--object {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.c-header-slider {
  padding-bottom: 42%;
}

.c-header-slider .flickity-viewport {
  position: absolute;
  width: 100%;
}

.c-header-slider .flickity-page-dots {
  z-index: 50;
  bottom: 25px;

  .dot {
    background: #fff;
  }
}
