/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  margin: 20px 0;
  height: 1px;
  box-sizing: border-box;
  width: 200px;
  border: 0.5px solid $color-red;
}
