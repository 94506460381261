.c-about {
  background: $color-light-grey;
  padding: 30px 15px;

  .alm-btn-wrap {
    display: block;
  }
}

.c-about-title {
  @include heading();
}

.c-about-content--hidden {
  display: none;
}
