.c-text {
  padding: 80px 20px;
}

.c-text-title {
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.c-text-title__desc {
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: -20px;
}

.c-text-content {
  margin-top: 40px;

  h2 {
    font-size: 16px;
    text-transform: uppercase;
  }

}

.c-text-btn-link {
  margin: 50px auto 0;
  display: block;
  text-align: center;

  a {
    color: $color-black;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    padding: 10px 15px;
    border: 1px solid $color-black;
    transition: all 0.5s ease-in-out;

    &:hover {
      color: $color-white;
      background: $color-black;
    }
  }
}
