.c-services {
  padding: 50px 15px;
  background: $color-light-grey;
}

.c-services-title {
  @include heading();
}

.c-service-item {
  margin: 10px 0;
  flex: 0 0 calc(100%);
  @media (min-width: 565px) {
    margin: 10px;
    flex: 0 0 calc(50% - 20px);
  }

  @media (min-width: 670px) {
    margin: 10px;
    flex: 0 0 calc(33% - 20px);
  }

  @media (min-width: 980px) {
    margin: 10px;
    flex: 0 0 calc(25% - 20px);
  }

  img {
    height: 80px;
    display: block;
    margin: 50px auto 10px;
  }

  h3 {
    font-size: 14px;
    text-align: center;
    padding: 15px;
    color: #fff;
    margin-bottom: 40px;
  }

}

.c-services-row {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bg-service-grey {
  background: $color-dark-grey;

  &:hover {
    background: $color-lessdark-grey;
  }
}

.bg-service-red {
  background: $color-red;

  &:hover {
    background: $color-light-red;
  }
}