@import 'flickity/css/flickity';

.flickity-prev-next-button {
    background: none;

    &:hover {
      background: none;
    }
    &.next, &.previous {
      width: 30px;
      height: 30px;
    }

    &.next {
      right: -50px;
    }

    &.previous {
      left: -50px;
    }
}
