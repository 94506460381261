/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;

  .c-image {
    img {
      width: 100%!important;
    }
  }
}

.c-header-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;

  .c-header-scroll {
    display: inline-block;
    font-size: 1.2em;
    text-transform: uppercase;
    color: $color-red;
    padding: 20px;
    

    span {
      position: relative;
      margin-right: 10px;
      top: 2px;
      letter-spacing: 1.5px;
    }

    img {
      width: 35px;
      height: 35px;
    }
  }
}
