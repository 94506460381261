.c-pricing__item {
  border: 1px solid $color-lessdark-grey;
  padding: 35px 25px;
  min-height: 350px;
}

.c-pricing__item--title {
  font-size: 1.6em;
  font-weight: bold;
  color: $color-red;
  border-bottom: $color-red 1px solid;
  padding-bottom: 15px;
}

.c-pricing__item--list {
  margin-top: 30px;
  color: $color-medium-grey;
  font-size: 1em;
  line-height: 1.1;
}