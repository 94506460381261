.job-listing {
  margin: 50px 0;
}

.job-listing__item {
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-bottom: $color-red solid 1px;


  @media (min-width: 600px) {
    display: flex;
  }
}

.job-listing__img-wrap {
  display: inline-block;
  position: relative;
}

.job-listing__helper {
  @media (min-width: 600px) {
    width: 40%;
  }
}

.c-job-listing__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

.job-listing__content {
  margin-top: 20px;

  p {
    margin-top: 15px;
    margin-bottom: 0;
  }

  ul {
    color: $color-medium-grey;
    
    li {
      margin: 0;
    }
  }

  @media (min-width: 600px) {
    width: 60%;
    padding-left: 25px;
  }
}

.job-listing__title {
  color: $color-red;
  font-size: 1.4em;
  margin-bottom: 20px;

  span {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.job-listing__description {
  font-size: 1.1em;
}

.apply-button {
  margin-top: 20px;
}

.c-jobs, .c-download {
  .c-gallery-navigation-text {
    color: $color-red!important;
    display: inline-block!important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .c-gallery-navigation-prev__icon svg g {
    fill: $color-red!important;
  }
}

.c-uslugi-listing {
  margin: 50px 0;
}

.c-uslugi-listing__item {
  padding: 40px 0;

  @media (min-width: 640px) {
    display: flex;
  }
}

.c-uslugi-listing__content {
  padding-top: 30px;

  @media (min-width: 640px) {
    padding-left: 40px;
  }
}

.c-uslugi-listing__img-wrap {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 80px;
  }

  h3 {
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-top: 20px;
  }
}
