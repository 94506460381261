.c-gallery {
  padding: 100px 0;
  padding: 30px 15px;
}

.c-gallery__loop {
  margin-top: 50px;
}

.c-gallery-title {
  @include heading();
}

.c-gallery__item {
  display: inline-block;
  position: relative;
  margin: 0 auto;
}

.c-gallery-item-image {
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.c-gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

.c-gallery-item-title {
  font-weight: 900;
}

.c-gallery-item-title, .c-gallery-item-info {
  color: $color-red;
  font-size: 1.4rem;

  @include bp-medium () {
    font-size: 1rem;
  }
}

.c-gallery__item {
  display: block;
  margin: 0 0 2.5em 0;
  transition: opacity 200ms ease-in-out;
  flex: 0 0 calc(33.33% - 2.5em);
  overflow: hidden;

  @include bp-medium() {
    margin: 0 2.5em 2.5em 0;
  }
  // margin-right: 10px;
  // height: 150px;
  // /* set min-width,
  //    allow images to determine cell width */
  // min-width: 150px;
  // max-width: 100%;

  img {
    height: 100%;
    // height: 150px;
    // /* set min-width,
    //    allow images to determine cell width */
    // min-width: 150px;
    // max-width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }
}

.c-gallery__thumbnail {
  margin-right: 10px;
  height: 150px;
  /* set min-width,
     allow images to determine cell width */
  min-width: 150px;
  max-width: 100%;

  img {
    height: 150px;
    /* set min-width,
       allow images to determine cell width */
    min-width: 150px;
    max-width: 100%;
  }
}

.c-gallery {
  .flickity-viewport {
    height: 150px !important;
  }
}

.c-gallery__image {
  display: block;
}

.c-gallery-navigation-prev {
  float: left;
}

.c-gallery-navigation-next {
  float: right;
}

.c-gallery-navigation-text {
  display: none;

  @include bp-medium() {
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 16px;
    color: $color-medium-grey;
  }
}
.c-gallery-navigation {
  padding: 0 15px;

  @include bp-large() {
    padding: 0;
  }
}
.c-gallery-navigation-next__icon {
  position: relative;
  top: 5px;
  transform: rotate(180deg);
}

.c-gallery-navigation-prev__icon {
  position: relative;
  top: 35px;
}

.c-gallery-navigation-prev__icon, .c-gallery-navigation-next__icon {
  width: 60px;
  display: inline-block;

  svg g {
    fill: $color-medium-grey;
  }
}

.single-galeria .c-text-title {
  color: $color-red;
}

.c-galeria-single-hero {
  width: 100%;
}

@media (max-width: 500px) {
  .flickity-button {
    display: none!important;
  }
}
